import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

import styles from "./Header.module.css";
import "@aws-amplify/ui-react/styles.css";
import Logo from "./Logo";

const Header = (props) => {
  return (
    <div className={styles.background}>
      <header>
        <div className={styles.header_content}>
          <Link to="/" className={styles.left}>
            <Logo></Logo>
          </Link>
          {typeof props.user == "undefined" && (
            <div className={styles.right}>
              <button
                onClick={props.signInHandler}
                style={{
                  backgroundColor: "#283185",
                  color: "#FFFFFF",
                  borderRadius: "12px",
                  width: "7rem",
                  maxWidth: "120px",
                }}
              >
                Sign in
              </button>
            </div>
          )}
          {typeof props.user != "undefined" && (
            <Authenticator>
              {({ signOut, user }) => (
                <div className={styles.right}>
                  <button
                    onClick={signOut}
                    style={{
                      backgroundColor: "#283185",
                      color: "#FFFFFF",
                      borderRadius: "12px",
                      width: "7rem",
                      maxWidth: "120px",
                    }}
                  >
                    Sign out
                  </button>
                </div>
              )}
            </Authenticator>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
