const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95.536"
      height="38.886"
      viewBox="0 0 95.536 38.886"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="95.536"
            height="38.886"
            transform="translate(0 0)"
            fill="#283185"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2"
        data-name="Group 2"
        transform="translate(-25.556 -10.402)"
      >
        <g
          id="Group_1"
          data-name="Group 1"
          transform="translate(25.556 10.402)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M0,33.232c0-7.414,6.04-14.975,10.8-14.975,3.387,0,5.253,1.62,5.253,5.057,0,4.125-2.405,7.316-9.132,8.838,0,2.21.442,3.437,2.357,3.437,3.339,0,7.659-2.847,10.458-7.119a1.764,1.764,0,0,1,1.866,1.62c0,3.633-8.2,11.441-14.582,11.441-4.812,0-7.021-3-7.021-8.3m9.574-9.82c-.687,0-2.013,1.67-2.357,5.106,2.75-.491,3.339-2.013,3.339-3.486,0-1.031-.393-1.62-.982-1.62"
            transform="translate(0 -3.233)"
            fill="#283185"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M34.045,33.24c-2.112,2.847-4.911,5.253-7.463,5.253-2.995,0-5.106-2.307-5.106-6.677,0-7.365,6.088-15.074,11-15.074C33.5,6.726,37.973,0,42.49,0c1.669,0,5.794.885,5.794,7.218,0,7.316-4.125,13.748-9.084,20.965.344,2.455,1.228,3.387,2.112,3.387,2.405,0,6.285-6.628,7.217-9.574a2.916,2.916,0,0,1-.737-2.013c0-2.553,2.357-9.475,4.861-9.475,1.669,0,3.339,1.816,3.339,2.847a8.1,8.1,0,0,1-1.326,3.584A20.123,20.123,0,0,0,58,18.313c2.6.835,3.241,1.522,3.241,2.652a9.1,9.1,0,0,1-1.277,3.682c-1.129,2.258-2.405,5.548-2.405,6.775,0,.688.2,1.178.834,1.178,1.374,0,4.812-3.044,7.414-7.119A1.873,1.873,0,0,1,67.874,27c0,3.093-8.4,11.832-12.913,11.832-3,0-4.664-1.325-4.664-4.124,0-3.241,2.9-8.3,2.9-9.575,0-.588-.54-.883-1.62-1.325C48.529,31.374,44.748,37.805,39.1,37.805c-2.357,0-4.026-1.571-5.057-4.566M28.1,29.95c0,1.227.49,1.914,1.227,1.914.834,0,2.21-1.276,3.535-3.191a65.677,65.677,0,0,1-.393-7.856A13.044,13.044,0,0,0,28.1,29.95m10.409-6.874C40.82,18.461,43.128,12.323,43.128,8.4c0-2.7-.344-3.142-.884-3.142-2.111,0-3.731,9.868-3.731,17.823"
            transform="translate(-3.802 0)"
            fill="#283185"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M72.957,33.232c0-7.414,6.04-14.975,10.8-14.975,3.387,0,5.253,1.62,5.253,5.057,0,4.125-2.405,7.316-9.132,8.838,0,2.21.442,3.437,2.357,3.437,3.339,0,7.659-2.847,10.458-7.119a1.764,1.764,0,0,1,1.866,1.62c0,3.633-8.2,11.441-14.582,11.441-4.812,0-7.021-3-7.021-8.3m9.574-9.82c-.687,0-2.013,1.67-2.357,5.106,2.75-.491,3.339-2.013,3.339-3.486,0-1.031-.393-1.62-.982-1.62"
            transform="translate(-12.917 -3.233)"
            fill="#283185"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M99.644,30.2a3.869,3.869,0,0,1-1.276,2.7c.393,2.16,2.061,3.633,3.191,3.633a1.006,1.006,0,0,0,1.031-1.13c0-2.5-2.848-8.445-2.848-11.489,0-4.124,3.339-6.971,7.807-6.971,2.848,0,4.517,1.227,4.517,3.584a3.925,3.925,0,0,1-1.031,2.651,6.808,6.808,0,0,0-2.6-.589c-1.031,0-1.571.295-1.571,1.179,0,2.209,2.8,6.284,2.8,9.868,0,5.942-5.058,8.249-9.476,8.249-3.83,0-6.825-2.6-6.825-7.463,0-2.9.785-7.659,3.191-7.659,1.718,0,3.093,2.21,3.093,3.437"
            transform="translate(-16.529 -3)"
            fill="#283185"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
