import Player from "../Player/Player";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { FaLock, FaCheck, FaDownload } from "react-icons/fa";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { Auth } from "aws-amplify";

import styles from "./CourseDetails.module.css";

export default function CourseDetails({ course }) {
  const [currentLecture, setCurrentLecture] = useState({
    description: "",
    id: -1,
    video_url: "",
    title: "",
    notes: "",
  });
  const [currentPlayedSeconds, setCurrentPlayedSeconds] = useState(0.0);
  const [currentVideoDuration, setCurrentVideoDuration] = useState(0.0);
  const [playing, setPlaying] = useState(false);
  const [courseProgress, setCourseProgress] = useState({
    CompletedLectures: [],
    Course: -1,
    CurrentLecture: 0,
    CurrentSecond: 0,
    Sub: "",
  });
  const [hasValidToken, setHasValidToken] = useState(false);
  const [triedToFetchToken, setTriedToFetchToken] = useState(false);

  const play = () => setPlaying(true);
  const pause = () => setPlaying(false);

  const UpdatePlayedSeconds = (seconds) => {
    setCurrentPlayedSeconds(seconds);
    setCourseProgress((prevState, props) => ({
      CompletedLectures: [
        ...prevState.CompletedLectures,
        ...(!prevState.CompletedLectures.includes(prevState.CurrentLecture) &&
        currentVideoDuration > 0 &&
        seconds / currentVideoDuration >= 0.9
          ? [prevState.CurrentLecture]
          : []),
      ],
      Course: prevState.Course,
      CurrentLecture: currentLecture.id,
      CurrentSecond:
        currentLecture.id === prevState.CurrentLecture ? seconds : 0,
      Sub: prevState.Sub,
    }));
  };
  const UpdateDuration = (seconds) => setCurrentVideoDuration(seconds);
  const updateCourseProgress = async (courseProgressParam) => {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      await axios.post(
        `/api/courseprogress?courseid=${course.id}`,
        courseProgressParam,
        {
          headers: {
            Authorization: "Bearer " + user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
          },
        }
      );
    }
  };
  useEffect(() => {
    if (courseProgress.Course !== -1) updateCourseProgress(courseProgress);
  }, [courseProgress, course.id]);

  useEffect(() => {
    const getCourseProgress = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const result = await axios.get(
        `/api/courseprogress?courseid=${course.id}`,
        {
          headers: {
            Authorization: "Bearer " + user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.data === "") {
        console.log("Couldn't fetch course..");
      } else {
        setCourseProgress(result.data);

        const current = course.chapters
          .filter(
            (chapter) =>
              chapter.lectures.filter(
                (el) => el.id === result.data.CurrentLecture
              ).length > 0
          )[0]
          .lectures.filter((el) => el.id === result.data.CurrentLecture);

        setCurrentLecture(current[0]);
      }
    };

    if (
      Object.entries(localStorage).filter(([key, value]) =>
        key.includes(".idToken")
      ).length > 0
    ) {
      getCourseProgress();
    }
  }, []);

  useEffect(() => {
    const getVideoToken = async () => {
      if (
        Object.entries(localStorage).filter(([key, value]) =>
          key.includes(".idToken")
        ).length > 0
      ) {
        const user = await Auth.currentAuthenticatedUser();
        try {
          const data = await axios.get("/api/generatetoken", {
            headers: {
              Authorization:
                "Bearer " + user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          });
          if (data.status === 200) {
            setHasValidToken(true);
          }
        } catch {}
      }
      setTriedToFetchToken(true);
    };

    getVideoToken();
  }, []);

  const LectureIcon = (props) => {
    if (
      props.lecture.video_url === "" ||
      (props.lecture.video_url.includes("/premium/") && !hasValidToken)
    ) {
      return (
        <div
          style={{
            backgroundColor: "#f2f2f2",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "1rem",
            fontSize: "1.6rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <FaLock
            style={{
              width: "1.3rem",
              height: "1.3rem",
              paddingTop: "0.4rem",
              color: "#999999",
            }}
          />
        </div>
      );
    } else if (props.lecture === currentLecture) {
      return (
        <div
          style={{
            width: "1.8rem",
            height: "1.8rem",
          }}
        >
          <CircularProgressbarWithChildren
            value={(currentPlayedSeconds / currentVideoDuration) * 100}
            strokeWidth="11"
            background={true}
            styles={buildStyles({
              pathColor: "#3399ff",
              trailColor: "none",
              backgroundColor: "#e6eeff",
            })}
          >
            <div>
              {playing && (
                <BsPauseFill
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    color: "#3399ff",
                  }}
                />
              )}
              {!playing && (
                <BsPlayFill
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    color: "#3399ff",
                    paddingLeft: "0.1rem",
                  }}
                />
              )}
            </div>
          </CircularProgressbarWithChildren>
        </div>
      );
    } else if (
      courseProgress.CompletedLectures.includes(props.lecture.id) &&
      (props.lecture.video_url.includes("/free/") ||
        (props.lecture.video_url.includes("/premium/") && hasValidToken))
    ) {
      return (
        <div
          style={{
            backgroundColor: "#3399ff",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "1rem",
            fontSize: "1.6rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <FaCheck
            style={{
              width: "1rem",
              height: "1rem",
              marginTop: "0.4rem",
              color: "#ffffff",
            }}
          />
        </div>
      );
    } else if (
      props.lecture !== currentLecture &&
      (props.lecture.video_url.includes("/free/") ||
        (props.lecture.video_url.includes("/premium/") && hasValidToken))
    ) {
      return (
        <div
          style={{
            backgroundColor: "#e6eeff",
            width: "1.8rem",
            height: "1.8rem",
            borderRadius: "1rem",
            fontSize: "1.6rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <BsPlayFill
            style={{
              //width: "2rem",
              //height: "2rem",
              paddingLeft: "0.1rem",
              paddingTop: "0.2rem",
              color: "#3399ff",
            }}
          />
        </div>
      );
    }
  };

  return (
    <main>
      <h3 className={styles.course_title}>{course.name}</h3>
      <div className={styles.course_container}>
        <ul className={styles.curriculum_list}>
          {course.chapters.map((chapter) => {
            return (
              <li key={chapter.id}>
                <div
                  style={{
                    color: "black",
                    backgroundColor: "#F5F5F5",
                    margin: "0.5rem",
                    borderRadius: "0.5rem",
                    boxShadow: "0 1px 4px rgb(0 0 0 / 16%)",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      borderRadius: "0.5rem 0.5rem 0 0",
                      paddingLeft: "0.5rem",
                      paddingRight: "0.5rem",
                      paddingTop: "0.5rem",
                      //boxShadow: "0 1px 4px rgb(0 0 0 / 16%)",
                    }}
                  >
                    {chapter.title}
                  </div>
                  <div
                    style={{
                      color: "white",
                      backgroundColor: "white",
                      borderRadius: "0 0 0.5rem 0.5rem",
                      padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                      //boxShadow: "0 1px 4px rgb(0 0 0 / 16%)",
                    }}
                  >
                    <ul style={{ listStyleType: "none", padding: "0px" }}>
                      {chapter.lectures.map((lecture, i) => {
                        return (
                          <>
                            <li
                              key={lecture.id}
                              style={{
                                paddingBottom: "0.5rem",
                                paddingTop: "0.5rem",
                                display: "flex",
                              }}
                            >
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  width: "100%",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (lecture === currentLecture) {
                                    if (playing) {
                                      pause();
                                    } else {
                                      play();
                                    }
                                  } else if (
                                    lecture.video_url === "" ||
                                    (lecture.video_url.includes("/premium/") &&
                                      !hasValidToken)
                                  ) {
                                  } else {
                                    setCurrentLecture(lecture);
                                    UpdatePlayedSeconds(0);
                                    play();
                                  }
                                }}
                              >
                                <LectureIcon lecture={lecture} />
                                <div
                                  style={{
                                    color:
                                      lecture === currentLecture &&
                                      currentLecture.video_url.length > 0
                                        ? "#3399ff"
                                        : lecture.video_url === "" ||
                                          (lecture.video_url.includes(
                                            "/premium/"
                                          ) &&
                                            !hasValidToken)
                                        ? "#999999"
                                        : "#525252",
                                    fontWeight: "bold",
                                    paddingBottom: "0.3rem",
                                    paddingTop: "0.3rem",
                                    width: "80%",
                                    fontSize: "0.8rem",
                                    marginLeft: "0.6rem",
                                  }}
                                >
                                  {lecture.title}
                                </div>
                              </button>
                              {lecture.notes &&
                                lecture.notes.includes("/premium/") &&
                                hasValidToken && (
                                  <a
                                    href={lecture.notes}
                                    download
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    <FaDownload
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                        display: "inline",
                                        color: "#3399ff",
                                      }}
                                    />
                                  </a>
                                )}
                            </li>
                            {i < chapter.lectures.length - 1 && (
                              <Divider light />
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <Player
            video_url={currentLecture.video_url}
            playing={playing}
            UpdatePlayedSeconds={UpdatePlayedSeconds}
            UpdateDuration={UpdateDuration}
            play={play}
            pause={pause}
            startTime={courseProgress.CurrentSecond}
            canPlay={triedToFetchToken}
          />
        </div>
      </div>
    </main>
  );
}
