import "./App.css";

import React, { useEffect, useState, useRef } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Courses from "./routes/Courses";
import Course from "./routes/Course";
import Lecture from "./routes/Lecture";

import { Route, Switch } from "react-router-dom";

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuthenticator((context) => [context.user]);
  const [isAuthenticating, setShowingAuthenticating] = useState(false);
  const [hasLoginCredentials, setHasLoginCredentials] = useState(false);

  function signInHandler() {
    setShowingAuthenticating(true);
  }

  async function fetchCourses() {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://oysz2vg9ob.execute-api.me-south-1.amazonaws.com/dev/courses"
      );
      if (!response.ok) {
        throw new Error("something went wrong!");
      }
      const data = await response.json();
      setCourses(data["Items"]);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCourses();
    if (typeof user != "undefined") {
      setShowingAuthenticating(false);
    }

    setHasLoginCredentials(
      Object.entries(localStorage).filter(([key, value]) =>
        key.includes(".idToken")
      ).length > 0
    );
  }, [user]);

  let content = <p>Couldn't find any courses :(</p>;
  if (courses.length > 0) {
    content = <Courses courses={courses} />;
  }
  if (error) {
    content = <p>{error}</p>;
  }
  if (isLoading) {
    content = <p>Loading...</p>;
  }

  return (
    <div className="App">
      <Header
        user={user}
        isAuthenticating={isAuthenticating}
        signInHandler={signInHandler}
      />
      {(hasLoginCredentials || isAuthenticating) && (
        <Authenticator variation="modal"></Authenticator>
      )}
      <main className="container">
        <Switch>
          <Route path="/" exact>
            {content}
          </Route>

          <Route path="/courses/:courseId">
            <Course />
          </Route>

          <Route path="/lectures/:lectureId">
            <Lecture />
          </Route>
        </Switch>
      </main>
      <Footer />
    </div>
  );
}

export default App;
