import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <Authenticator.Provider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Authenticator.Provider>,
  document.getElementById('root')
);

