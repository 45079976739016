import { useLocation } from "react-router-dom";
import CourseDetails from "../components/CourseDetails/CourseDetails";

export default function Course() {
  const { course } = useLocation().state;

  return (
      <CourseDetails course={course}/>
  );
}
