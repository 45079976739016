import React from "react";
import { FaHeart } from "react-icons/fa";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      Made with <FaHeart style={{ color: "red", display: "inline" }} /> in
      Kuwait
    </footer>
  );
};

export default Footer;
