import React, { useState } from "react";
import ReactPlayer from "react-player";
import styles from "./Player.module.css";
import { isiOS } from "../../helpers/platform";

const Player = (props) => {
  const [playerError, setPlayerError] = useState(false);
  const ref = React.createRef();
  const [readyURL, setReadyURL] = useState("");

  const videoConfig = {
    file: {
      forceHLS: !isiOS(),
      hlsOptions: {
        maxBufferSize: 0,
        maxBufferLength: 12,
        xhrSetup: function xhrSetup(xhr, url) {
          if (!props.video_url.includes("/free/")) {
            xhr.withCredentials = true;
          }
        },
      },
    },
  };

  return (
    <>
      {!playerError && props.canPlay && (
        <div className={styles.player_wrapper}>
          <ReactPlayer
            ref={ref}
            className={styles.react_player}
            url={props.video_url}
            controls={true}
            progressInterval={6000}
            playing={props.playing}
            onReady={() => {
              if (readyURL !== props.video_url) {
                ref.current.seekTo(props.startTime);
                props.UpdatePlayedSeconds(props.startTime);
                setReadyURL(props.video_url);
              }
            }}
            onPlay={props.play}
            onPause={props.pause}
            width="100%"
            height="100%"
            style={{ maxHeight: "700px", backgroundColor: "black" }}
            light={props.thumbnail_url}
            config={videoConfig}
            onProgress={(progress) => {
              props.UpdatePlayedSeconds(progress.playedSeconds);
            }}
            onDuration={(duration) => {
              props.UpdateDuration(duration);
            }}
          ></ReactPlayer>
        </div>
      )}
      {/* {playerError && (
        <div className={styles.player_error_wrapper}>
          <h1>Oops</h1>
          <h3>It seems like an issue occured..</h3>
        </div>
      )} */}
    </>
  );
};

export default Player;
