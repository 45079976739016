import styles from "./CoursesList.module.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

function CoursesList({ courses }) {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ justifyContent: "center" }}
    >
      {courses.map((course) => {
        return (
          <Grid item xs={"auto"}>
            <Link
              style={{ height: "100%" }}
              to={{
                pathname: course.available ? `/courses/${course.id}` : "",
                state: { course: course },
              }}
            >
              <li
                className={styles.cards__item}
                key={course.id}
                style={{ height: "100%" }}
              >
                <div className={styles.card}>
                  <div
                    style={{
                      backgroundColor: "#283185",
                      height: "50px",
                      paddingLeft: "1rem",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        lineHeight: "50px",
                        fontWeight: "500",
                      }}
                    >
                      {course.totalChapters} Chapters
                    </div>
                  </div>
                  {/* <div className={styles.card__image}>
                    <img src={course.image_url} alt="" />
                  </div> */}
                  <div className={styles.card__content}>
                    <div className={styles.card__title}>{course.name}</div>
                    <p className={styles.card__text}>{course.description}</p>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {course.available && (
                        <p
                          style={{
                            color: "#283185",
                            fontSize: ".9rem",
                            fontWeight: "bold",
                          }}
                        >
                          Join
                        </p>
                      )}
                      {!course.available && (
                        <p style={{ color: "#848489", fontSize: ".9rem" }}>
                          Coming Soon..
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CoursesList;
