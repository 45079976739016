import React from "react";
import Player from "../components/Player";
import { useLocation, useParams, Link } from "react-router-dom";

const Lecture = () => {
  const params = useParams();
  const lecture_data = useLocation().state.lecture_data;

  return (
    <div>
      <div style={{ "max-width": "750px", margin: "0 auto" }}>
        <Player
          video_url={lecture_data.video_url}
          thumbnail_url={lecture_data.thumbnail_url}
        />
      </div>{" "}
      <div className="container">
        <h1>{lecture_data.title}</h1>
        <p>{lecture_data.description}</p>
      </div>
    </div>
  );
};

export default Lecture;
